// blog max 61.9375rem

.blog {

  .blog_blocks {
    -ms-grid-columns: 1fr 1fr;
    grid-template-columns: 1fr 1fr;
    -ms-grid-rows: 50% 25% 25%;
    grid-template-rows: 50% 25% 25%;
    height: 140vh;
    max-height: unset;

    .blog_block:nth-child(1) {
      -ms-grid-column: 1;
      -ms-grid-column-span: 2;
      -ms-grid-row: 1;
      grid-column: 1/3;
      grid-row: 1/2;
    }

    .blog_block:nth-child(2) {
      -ms-grid-column: 1;
      -ms-grid-row: 2;
      -ms-grid-row-span: 2;
      grid-column: 1/2;
      grid-row: 2/4;
    }

    .blog_block:nth-child(3) {
      -ms-grid-column: 2;
      -ms-grid-row: 2;
      grid-column: 2/3;
      grid-row: 2/3;
    }

    .blog_block:nth-child(4) {
      -ms-grid-column: 2;
      -ms-grid-row: 3;
      grid-column: 2/3;
      grid-row: 3/4;
    }
  }
}