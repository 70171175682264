// basic styles
//@import "style_base.scss";

// breakpoint max-width: 62rem
@media screen and (max-width: 62rem) {
  @import "general SCSS files/menu_max_61-9375rem";
  @import "ContentElements/blog/blog_max_61-9375rem.scss";
  @import "ContentElements/cover/cover_max_61-9375rem.scss";
  @import "ContentElements/divider_cover/divider_cover_max_61-9375rem.scss";
  @import "ContentElements/reference_slider/reference_slider_max_61-9375rem.scss";
  @import "ContentElements/service/service_max_61-9375rem.scss";
}
