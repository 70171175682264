// reference_slider max 61.9375rem

.reference_slider {

  .reference_slider_container {

    .reference_slider_items {

      .reference_slider_item {
        -ms-flex: 0 0 33.333%;
        flex: 0 0 33.333%;
      }
    }
  }
}