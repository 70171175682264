// divider_cover max 61.9375rem

.divider_cover {

  .divider_cover_container {

    .divider_cover_mask {
      right: -13rem;
    }

    .divider_cover_headlines {

      h2 {
        width: 100%;
        font-size: 2.5rem;

        em {
          width: calc(100% - 9rem);
          font-size: 3rem;
        }
      }
    }

    .button {
      bottom: 2.5rem;
      right: 1.5rem;
    }
  }
}