// service max 61.9375rem

.service {

  .service_container {

    &.three_columns {

      .service_item {
        margin-left: 1rem;
        margin-right: 1rem;
        width: calc(50% - 2rem);
      }
    }

    &.four_columns {

      .service_item {
        margin-left: 1rem;
        margin-right: 1rem;
        width: calc(50% - 2rem);
      }
    }
  }
}