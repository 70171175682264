// menu max 61.9375rem

/* menu */

nav {

  .main.navbar {

    .navbar-collapse {
      max-height: calc(100vh - 6rem);
      overflow-y: scroll;

      .navbar-nav {
        height: unset;

        .nav-item {
          width: auto;
          height: auto;

          > a {
            position: relative;
            top: initial;
            transform: initial;
          }
        }
      }
    }
  }
}



