// cover max 61.9375rem

.cover {

  .cover_container {

    .cover_mask {
      right: -18rem;
    }

    .cover_headlines {
      width: calc(100% - 5rem);
    }

    .button {
      bottom: 2.5rem;
      right: 1.5rem;
    }
  }
}